import React, { Component, Fragment } from "react";
import "./index.less";
import Footer from "@/components/Footer/index.js";
import { caseDatas } from './CaseDetail/data'
import { useTranslation } from 'react-i18next';
import ConcatUs from './components/ConcatUs/index.js'
import ProjectCases from '../Home/component/ProjectCases/index'
import { Row, Col } from "antd";

function Case() {
  const { t, i18n } = useTranslation();
    return (
      <Fragment>
        <div className="case-page">
          <div className="case-page-header">
            <h1>Our Products</h1>
          </div>
          <Row>
            <Col xs={24} sm={24}>
              <ProjectCases
                caseDatas={caseDatas}
                className='case-page-container'
                imgName='showImgs'
                showImgClassName='case-page-container-show'
              />
            </Col>
          </Row>
        </div>
      <Footer />
    </Fragment>
  );
}

export default Case;
