import en from './en.json';
import zh from './zh.json';
const resources = {
  en: {
    translation: en
  },
  zh: {
    translation: zh
  }
}
export default resources;