import React, { useState } from 'react'
import { Row, Col } from "antd";
import reactIcon from "@/assets/img/home-page/web/react.svg";
import vueIcon from "@/assets/img/home-page/web/vue.svg";
import html5Icon from "@/assets/img/home-page/web/html5.svg";
import wechatIcon from "@/assets/img/home-page/web/wechat.svg";
import rubyIcon from "@/assets/img/home-page/web/ruby.svg";
import nodeIcon from "@/assets/img/home-page/web/js.svg";
import javaIcon from "@/assets/img/home-page/web/Java.svg";
import golangIcon from "@/assets/img/home-page/web/golang.svg";
import POSTGRESQLIcon from "@/assets/img/home-page/web/POSTGRESQL.svg";
import redisIcon from "@/assets/img/home-page/web/redis.svg";
import mysqlIcon from "@/assets/img/home-page/web/mysql.svg";
import elasticsearchIcon from "@/assets/img/home-page/web/elasticsearch.svg";
import aliIcon from "@/assets/img/home-page/web/ali.svg";
import awsIcon from "@/assets/img/home-page/web/aws.svg";
import herokuIcon from "@/assets/img/home-page/web/heroku.svg";
import tencentIcon from "@/assets/img/home-page/web/tencent.svg";
import swiftIcon from "@/assets/img/home-page/app/swift.svg";
import objectCIcon from "@/assets/img/home-page/app/object-c.svg";
import androidIcon from "@/assets/img/home-page/app/Android.svg";
import reactNativeIcon from "@/assets/img/home-page/app/react.svg";
import xamarinIcon from "@/assets/img/home-page/app/xamarin.svg";
// active icon
import reactIconActive from "@/assets/img/home-page/web-active/react.svg";
import vueIconActive from "@/assets/img/home-page/web-active/vue.svg";
import html5IconActive from "@/assets/img/home-page/web-active/html5.svg";
import wechatIconActive from "@/assets/img/home-page/web-active/wechat.svg";
import rubyIconActive from "@/assets/img/home-page/web-active/ruby.svg";
import nodeIconActive from "@/assets/img/home-page/web-active/js.svg";
import javaIconActive from "@/assets/img/home-page/web-active/Java.svg";
import golangIconActive from "@/assets/img/home-page/web-active/golang.svg";
import POSTGRESQLIconActive from "@/assets/img/home-page/web-active/POSTGRESQL.svg";
import redisIconActive from "@/assets/img/home-page/web-active/redis.svg";
import mysqlIconActive from "@/assets/img/home-page/web-active/mysql.svg";
import elasticsearchIconActive from "@/assets/img/home-page/web-active/elasticsearch.svg";
import aliIconActive from "@/assets/img/home-page/web-active/ali.svg";
import awsIconActive from "@/assets/img/home-page/web-active/aws.svg";
import herokuIconActive from "@/assets/img/home-page/web-active/heroku.svg";
import tencentIconActive from "@/assets/img/home-page/web-active/tencent.svg";
import swiftIconActive from "@/assets/img/home-page/app-active/swift.svg";
import objectCIconActive from "@/assets/img/home-page/app-active/object-c.svg";
import androidIconActive from "@/assets/img/home-page/app-active/Android.svg";
import reactNativeIconActive from "@/assets/img/home-page/app-active/react.svg";
import xamarinIconActive from "@/assets/img/home-page/app-active/xamarin.svg";
import { isMobile } from "@/utils/utils"
import { useTranslation } from 'react-i18next';
import Expanse from "react-expanse"
import {
  UpOutlined
} from '@ant-design/icons';
import "./index.less";

const TechnologyStack = (props) => {
	const { t, i18n } = useTranslation();
	const { showTechnology, setShowTechnology } = props;
	const technologyArr = [
		{
			key: '1',
			label: 'WEB',
			children: [
				{
					title: t('前端'),
					children: [
						{ label: 'REACT', icon: reactIcon, activeIcon: reactIconActive },
						{ label: 'VUE', icon: vueIcon, activeIcon: vueIconActive},
						{ label: 'HTML5', icon: html5Icon, activeIcon: html5IconActive},
						{ label: 'WECHAT', icon: wechatIcon, activeIcon: wechatIconActive},
					]
				},
				{
					title: t('后端'),
					children: [
						{ label: 'RUBY ON RAILS', icon: rubyIcon, activeIcon: rubyIconActive},
						{ label: 'NODE JS', icon: nodeIcon, activeIcon: nodeIconActive},
						{ label: 'JAVA', icon: javaIcon, activeIcon: javaIconActive},
						{ label: 'GOLANG', icon: golangIcon, activeIcon: golangIconActive},
					]
				},
				{
					title: t('数据库'),
					children: [
						{ label: 'POSTGRESQL', icon: POSTGRESQLIcon, activeIcon: POSTGRESQLIconActive},
						{ label: 'REDIS', icon: redisIcon, activeIcon: redisIconActive},
						{ label: 'MYSQL', icon: mysqlIcon, activeIcon: mysqlIconActive},
						{ label: 'ELASTICSEARCH', icon: elasticsearchIcon, activeIcon: elasticsearchIconActive},
					]
				},
				{
					title: t('云服务'),
					children: [
						{ label: 'AWS', icon: awsIcon, activeIcon: awsIconActive},
						{ label: 'HEROKU', icon: herokuIcon, activeIcon: herokuIconActive},
						{ label: 'TENCENT', icon: tencentIcon, activeIcon: tencentIconActive},
						{ label: 'ALIBABA', icon: aliIcon, activeIcon:aliIconActive },
					]
				},
			],
		},
		{
			key: '2',
			label: 'APP',
			children: [
				{
					title: 'IOS',
					children: [
						{ label: 'SWIFT', icon: swiftIcon, activeIcon:swiftIconActive },
						{ label: 'OBJECTIVE-C', icon: objectCIcon, activeIcon: objectCIconActive},
					]
				},
				{
					title: 'ANDROID',
					children: [
						{ label: 'JAVA', icon: javaIcon, activeIcon: javaIconActive},
						{ label: 'ANDROID', icon: androidIcon, activeIcon: androidIconActive},
					]
				},
				{
					title: 'HYBRID',
					children: [
						{ label: 'REACT NATIVE', icon: reactNativeIcon, activeIcon: reactNativeIconActive},
						{ label: 'XAMARIN', icon: xamarinIcon, activeIcon: xamarinIconActive},
					]
				}
			],
		}
	]
	const [currentKey, setCurrentKey] = useState('1');
	const [isUpdate, setIsUpdate] = useState(false);
	const arr = technologyArr.find(item => item.key === currentKey).children;
  return (
		<Expanse show={showTechnology}>
			<div className="technology-stack">
				<div className="technology-stack-title">
					<div className="stack-title1">TECHNOLOGY</div>
					<div className="stack-title2">技术栈</div>
				</div>
				<div className="technology-stack-content" style={{ width: currentKey === '1' ? 488 : 218 }}>
					<div className="technology-tabs">
						{
							technologyArr.map(item => (
								<div
									className={`tab-li ${currentKey === item.key && 'ac-tab-li'}`}
									key={item.key}
									onClick={() => {
										setCurrentKey(item.key)
										setIsUpdate(!isUpdate)
									}}
								>
									{item.label}
								</div>
							))
						}
					</div>
					{
						technologyArr.map(item => currentKey === item.key && (
							<div className={`technology-warp ${currentKey === item.key && 'technology-warp1'}`}>
								{
									arr.map((item, index) => (
										<div className="technology-li" key={`${index}`}>
											<div className="technology-li-title">{item.title}</div>
											<div className="flex-center stack-list">
												{
													item.children.map((each, i) => (
														<div key={`${i}`} className="stack-li">
															<div className="stack-li-icon">
																<img  className="stack-li-icon-inactive" alt="" src={each.icon} />
																<img  className="stack-li-icon-active" alt="" src={each.activeIcon}/>
															</div>
															<div className="stack-li-label">{each.label}</div>
														</div>
													))
												}
											</div>
										</div>
									))
								}
							</div>
						))
					}
				</div>
				<div className="pack-up">
					<UpOutlined onClick={() => setShowTechnology(false)}  />
				</div>
			</div>
		</Expanse>
  )
}

export default TechnologyStack;
