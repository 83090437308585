import React from "react";
import "./index.less";
import ButtonArrow from "@/components/ButtonArrow/index.js";
import homeMap from "@/assets/img/home-page/home-map.svg";
import { useTranslation } from 'react-i18next';
import { openMeiqia } from "@/utils/utils"


function ConcatUs () {
  const { t, i18n } = useTranslation();
  return (
    <>
      <section className="footer-page-concat">
        <div className="footer-page-concat-title">{t('对我们的服务感兴趣？')}</div>
        <div className="footer-page-concat-content">{t('不论您有一个好的创业想法需要技术支持，还是现有软件系统需要更新升级，您的任何技术问题都可以跟我们沟通，让我们为您提供更专业的建议。')}</div>
        <ButtonArrow
          btnArrow = {{
            style: {background: '#496DEF'},
            onClick: () => {
              openMeiqia()
            }
          }}
          title={t('在线咨询')}
        />
      </section>
      <div className="footer-page-footer-line"></div>
      <img alt='' src={homeMap} style={{width: '100%'}}></img>
    </>
  );
}

export default ConcatUs;
