/*
 * @Description: 
 * @Autor: xingfutan
 * @Date: 2021-08-04 13:46:23
 * @LastEditors: xingfutan
 * @LastEditTime: 2021-08-04 14:53:09
 */
import React, { Component, Fragment } from "react";
import "./index.less";
import Footer from "@/components/Footer/index.js";
import ButtonArrow from "@/components/ButtonArrow/index.js";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { isMobile, isZh } from "@/utils/utils"
import CoreTeam from '../Home/component/CoreTeam';
import ConcatUs from '../Case/components/ConcatUs';

function About() {
  const { t, i18n } = useTranslation();
  const history = useHistory()
    return (
      <Fragment>
        <div className="about-page">
          <div className="about-page-header">
            <h1>Our Team</h1>
            <h4>A mix tech team of highly skilled and incredibly dedicated craftsmen.</h4>
          </div>
        </div>
        <CoreTeam isMobile={isMobile} pageStyle={{ marginTop: 40 }} />
      
      <Footer />
    </Fragment>
  );
}

export default About;
