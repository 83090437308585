import React, { Component, Fragment, useEffect, useState } from "react";
import { Row, Col, Divider } from "antd";
import Typed from "typed.js";
import anime from "animejs/lib/anime.es.js";
import {
  UpOutlined
} from '@ant-design/icons';
import "./index.less";
import Footer from "@/components/Footer/index.js";
import {getParameters} from '@/utils/utils';

import stripeLeft from "@/assets/img/home-page/stripe-left.png";
import stripeRight from "@/assets/img/home-page/stripe-right.png";
import homeDevelopment from "@/assets/img/home-page/home-development.png";
import homeMapMobile from "@/assets/img/home-page/home-map-mobile.svg";

import iconCheese from "@/assets/icon/icon-cheese.png";
import iconCosmopolitan from "@/assets/icon/icon-cosmopolitan.png";
import iconPriority from "@/assets/icon/icon-priority.png";
import iconPrismpop from "@/assets/icon/icon-prismpop.png";
import iconTrashaus from "@/assets/icon/icon-trashaus.png";
import iconTripalink from "@/assets/icon/icon-tripalink.png";

import iconInfibraintech from "@/assets/icon/icon-infibraintech.png";
import iconMoego from "@/assets/icon/icon-moego.png";
import iconOuter from "@/assets/icon/icon-outer.png";
import iconRabbit from "@/assets/icon/icon-rabbit.png";

import BusinessProcess from './component/BusinessProcess';
import SuccessfulCases from './component/SuccessfulCases';
import TechnologyStack from './component/TechnologyStack';
import CoreTeam from './component/CoreTeam';
import { isMobile, isZh, openMeiqia } from "@/utils/utils"
import ButtonArrow from "@/components/ButtonArrow/index.js";
import Expanse from "react-expanse"
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

function Home() {
  const history = useHistory()
  const { t, i18n } = useTranslation();
  const [showTechnology, setShowTechnology] = useState(false);
  const [showCoreTeam, setShowCoreTeam] = useState(false);
	const {lang} = getParameters();
  const clientsList = [
    // {
    //   logo: iconRadpad,
    // },
    {
      logo: iconCosmopolitan,
    },
    // {
    //   logo: iconHarmay,
    // },
    {
      logo: iconTrashaus,
    },
    {
      logo: iconMoego,
    },
    {
      logo: iconCheese,
    },
    // {
    //   logo: iconKEmsstudio,
    // },
    {
      logo: iconPrismpop,
    },
    {
      logo: iconTripalink,
    },
    {
      logo: iconPriority,
    },
    {
      logo: iconRabbit,
    },
    {
      logo: iconOuter,
    },
    {
      logo: iconInfibraintech,
    },
    // {
    //   logo: iconLanxiang
    // },
    // {
    //   logo: iconShopshops,
    // },
  ];
  useEffect(() => {
    let lsLocal = localStorage.getItem('blur-website-locale');
    if (lsLocal) {
      i18n.changeLanguage(lsLocal)
    } else {
      const validLang = ['zh', 'en'].includes(lang) ? lang : 'zh'
      i18n.changeLanguage(validLang)
      localStorage.setItem('blur-website-locale', validLang);
    }
    new Typed("#blurtyped", {
      strings: [
        "bool isInRect(Vec2 point)",
        "Vec2 left_top = position - size / 2.0;\nVec2 right_buttom = position + size / 2.0;\nif (point.x >= left_top.x && point.y >= left_top.y &&\npoint.x <= right_buttom.x && point.y <= right_buttom.y)return true;\n",
        "#include <stdio.h>\ndouble cube volume(double side length)\n{\nreturn side length * side length * side length;\n}",
        "start with NKS!",
      ],
      typeSpeed: 50,
      startDelay: 300,
      loop: true,
    })

    function fitElementToParent(el, padding) {
      var timeout = null;
      function resize() {
        if (timeout) clearTimeout(timeout);
        anime.set(el, { scale: 1 });
        var pad = padding || 0;
        var parentEl = el.parentNode;
        var elOffsetWidth = el.offsetWidth - pad;
        var parentOffsetWidth = parentEl.offsetWidth;
        var ratio = parentOffsetWidth / elOffsetWidth;
        timeout = setTimeout(anime.set(el, { scale: ratio }), 10);
      }
      resize();
      window.addEventListener("resize", resize);
    }

    var advancedStaggeringAnimation = (function () {
      var staggerVisualizerEl = document.querySelector(".stagger-visualizer");
      var dotsWrapperEl = staggerVisualizerEl.querySelector(".dots-wrapper");
      var dotsFragment = document.createDocumentFragment();
      var grid = [20, 10];
      var cell = 55;
      var numberOfElements = grid[0] * grid[1];
      var animation;
      var paused = true;

      fitElementToParent(staggerVisualizerEl, 0);

      for (var i = 0; i < numberOfElements; i++) {
        var dotEl = document.createElement("div");
        dotEl.classList.add("dot");
        dotsFragment.appendChild(dotEl);
      }

      dotsWrapperEl.appendChild(dotsFragment);

      var index = anime.random(0, numberOfElements - 1);
      var nextIndex = 0;

      function play() {
        paused = false;
        if (animation) animation.pause();

        nextIndex = anime.random(0, numberOfElements - 1);

        animation = anime
          .timeline({
            easing: "easeInOutQuad",
            complete: play,
          })
          .add(
            {
              targets: ".stagger-visualizer .dot",
              keyframes: [
                {
                  translateX: anime.stagger("-2px", {
                    grid: grid,
                    from: index,
                    axis: "x",
                  }),
                  translateY: anime.stagger("-2px", {
                    grid: grid,
                    from: index,
                    axis: "y",
                  }),
                  duration: 100,
                },
                {
                  translateX: anime.stagger("4px", {
                    grid: grid,
                    from: index,
                    axis: "x",
                  }),
                  translateY: anime.stagger("4px", {
                    grid: grid,
                    from: index,
                    axis: "y",
                  }),
                  scale: anime.stagger([2.6, 1], { grid: grid, from: index }),
                  duration: 225,
                },
                {
                  translateX: 0,
                  translateY: 0,
                  scale: 1,
                  duration: 1200,
                },
              ],
              delay: anime.stagger(80, { grid: grid, from: index }),
            },
            30
          )
        index = nextIndex;
      }
      play()
    })()

    var advancedMobileStaggeringAnimation = (function () {
      var staggerVisualizerEl = document.querySelector(".stagger-visualizer-mobile");
      var dotsWrapperEl = staggerVisualizerEl.querySelector(".dots-wrapper");
      var dotsFragment = document.createDocumentFragment();
      var grid = [6, 10];
      var cell = 55;
      var numberOfElements = grid[0] * grid[1];
      var animation;
      var paused = true;

      fitElementToParent(staggerVisualizerEl, 0);

      for (var i = 0; i < numberOfElements; i++) {
        var dotEl = document.createElement("div");
        dotEl.classList.add("dot");
        dotsFragment.appendChild(dotEl);
      }

      dotsWrapperEl.appendChild(dotsFragment);

      var index = anime.random(0, numberOfElements - 1);
      var nextIndex = 0;

      function play() {
        paused = false;
        if (animation) animation.pause();

        nextIndex = anime.random(0, numberOfElements - 1);

        animation = anime
          .timeline({
            easing: "easeInOutQuad",
            complete: play,
          })
          .add(
            {
              targets: ".stagger-visualizer-mobile .dot",
              keyframes: [
                {
                  translateX: anime.stagger("-2px", {
                    grid: grid,
                    from: index,
                    axis: "x",
                  }),
                  translateY: anime.stagger("-2px", {
                    grid: grid,
                    from: index,
                    axis: "y",
                  }),
                  duration: 100,
                },
                {
                  translateX: anime.stagger("4px", {
                    grid: grid,
                    from: index,
                    axis: "x",
                  }),
                  translateY: anime.stagger("4px", {
                    grid: grid,
                    from: index,
                    axis: "y",
                  }),
                  scale: anime.stagger([2.6, 1], { grid: grid, from: index }),
                  duration: 225,
                },
                {
                  translateX: 0,
                  translateY: 0,
                  scale: 1,
                  duration: 2500,
                },
              ],
              delay: anime.stagger(80, { grid: grid, from: index }),
            },
            30
          )
        index = nextIndex;
      }
      play()
    })()
  }, []);
  return (
    <Fragment>
      <div className="home-page">
        <section style={{background: '#522469', paddingBottom: '50px'}}>
          <div className="home-page-header">
            <div className="home-page-header-bg">
              <div className="stagger-visualizer">
                <div className="dots-wrapper"></div>
              </div>
            </div>
            <div className="home-page-header-bgmobile">
              <div className="stagger-visualizer-mobile">
                <div className="dots-wrapper"></div>
              </div>
            </div>
            <div className="home-page-header-text">
              <h4>Born for surf - Redefining your gear</h4>
              <h1>
                <strong>Nature + Keep = Smart</strong>
              </h1>
              <h2>
              Let's make things better.
              </h2>
            </div>
            {/* <div className="home-page-header-consult-btn">
              <ButtonArrow
                btnArrow = {{
                  style: {background: '#C9394C', alignSelf: 'flex-start', marginTop: isZh() === true ? '13px' : '30px'},
                  onClick: () => {
                    openMeiqia()
                  }
                }}
                isAnimation={true}
                title="About Us"
              />
            </div> */}
          </div>
        </section>
        <section className="home-page-advantage">
          <Row>
            <Col xs={3} sm={0}></Col>
            <Col xs={18} sm={11}>
              <div className="home-page-advantage-left">
                <div className="home-page-advantage-left-subtitle">
                 COMPARATIVE ADVANTAGE
                </div>
                <div className="home-page-advantage-left-title">
                WISDOM EMPOWERMENT
                </div>
                <div className="home-page-advantage-left-text">
                We use computer programs to create high-precision board models.Count and calculate each test result, and use machine learning technology to combine the analysis results with the team's ideas to create the best product.
                </div>
                {/* <div onClick={() => setShowTechnology(!showTechnology)} className="home-page-advantage-left-detail-btn">
                  <ButtonArrow
                    btnArrow = {{
                      style: {alignSelf: 'flex-start'},
                      onClick: () => { }
                    }}
                    type="lower"
                    isAnimation={false}
                    title="View our product categories"
                  />
                </div> */}
              </div>
            </Col>
            <Col xs={3} sm={1}></Col>
            <Col xs={3} sm={0}></Col>
            <Col xs={18} sm={12}>
              <div className="text-editor-wrap">
                <div className="title-bar">
                  <span className="title">NKS - bash 80x24</span>
                </div>
                <div className="text-body">
                  <span id="blurtyped"></span>
                </div>
              </div>
            </Col>
          </Row>
        </section>
        <section className="technology-section">
          <Row>
            <Col xs={3} sm={0}></Col>
            <Col xs={18} sm={24}>
              <TechnologyStack setShowTechnology={setShowTechnology} showTechnology={showTechnology} />
            </Col>
            <Col xs={3} sm={3}></Col>
          </Row>
        </section>
        <img
          alt="stripe"
          className="home-page-stripe-right"
          src={stripeRight}
        />
        <section className="home-page-case">
          <Row>
            <Col xs={3} sm={0}></Col>
            <Col xs={18} sm={24}>
              <SuccessfulCases />
            </Col>
            <Col xs={3} sm={0}></Col>
          </Row>
          {/* <SuccessfulCases /> */}
          <div className="home-page-case-more-btn">
            <ButtonArrow
              btnArrow = {{
                style: {background: '#C9394C', alignSelf: 'flex-start'},
                onClick: () => { history.push(`/products`) }
              }}
              isAnimation={true}
              title="All Products"
            />
          </div>
          
        </section>
      <img alt="stripe" className="home-page-stripe-left" src={stripeLeft} />
        <section className="home-page-development">
          <Row>
            <Col xs={3} sm={0}></Col>
            <Col xs={18} sm={11} style={{display: 'flex', alignItems: 'center'}}>
              <div className="home-page-development-left">
                <div className="home-page-development-left-subtitle">
                 TECHNICAL DEVELOPMENT
                </div>
                <div className="home-page-development-left-title">DEVELOPMENT PROCESS</div>
                <div className="home-page-development-left-text">
                It’s safe to say we’re not done with developing new ideas and products and we’re continuing to explore ways to make your next ride unlike any other.
                </div>
              </div>
            </Col>
            <Col xs={0} sm={4}></Col>
            <Col xs={18} sm={9}>
              <div className="home-page-development-right">
                <div className="home-page-development-right-img">
                  <img alt="development" src={homeDevelopment} style={{width: '100%'}}/>
                </div>
              </div>
            </Col>
            <Col xs={3} sm={0}></Col>
          </Row>
        </section>
        <section>
          <Row>
            <Col xs={3} sm={0}></Col>
            <Col xs={18} sm={24}>
              <BusinessProcess />
            </Col>
            <Col xs={3} sm={0}></Col>
          </Row>
        </section>
        <section className="home-page-aboutus">
          <Row>
            <Col xs={3} sm={0}></Col>
            <Col xs={18} sm={11}>
              <div className="home-page-aboutus-left">
                <div className="home-page-aboutus-left-subtitle">
                  ABOUT US
                </div>
                <div className="home-page-aboutus-left-title">Introduction</div>
                <div className="home-page-aboutus-left-text">
                NKS is an enterprise with a global perspective engaged in the development of aquatic sports products. The company's headquarters are located in Los Angeles, USA.We took years of R&D, components sourcing, design, and engineering.We’ve been riding the products we’ve been making since the beginning, so the constant pursuit for the ultimate product has always been a part of our DNA. We don’t cut corners. We just obsessively try to perfect.
                </div>
                <div className="home-page-aboutus-left-more-btn" onClick={() => setShowCoreTeam(!showCoreTeam)}>
                  <ButtonArrow
                    btnArrow = {{
                      style: {alignSelf: 'flex-start'},
                      onClick: () => { }
                    }}
                    type="lower"
                    isAnimation={false}
                    title="Show Core Members"
                  />
                </div>
              </div>
            </Col>
            <Col xs={3} sm={5}></Col>
            <Col xs={3} sm={0}></Col>
            <Col xs={18} sm={8}>
              <div className="home-page-aboutus-right">
                <Row>
                  <Col xs={7} sm={24}>
                    <div className="home-page-aboutus-right-count">5+</div>
                    <div className="home-page-aboutus-right-title">
                    5+ Years of Experience
                    </div>
                  </Col>
                  <Col xs={10} sm={24}>
                    <div className="home-page-aboutus-right-count">10+</div>
                    <div className="home-page-aboutus-right-title">
                    Sr. Enginners
                    </div>
                  </Col>
                  <Col xs={7} sm={24}>
                    <div className="home-page-aboutus-right-count">20+</div>
                    <div className="home-page-aboutus-right-title">
                    Products
                    </div>
                  </Col>
                </Row>
                <Row className="home-page-aboutus-right-text">
                  <Col xs={24} sm={0}>
                    <div>
                    We are a mix tech team of highly skilled and incredibly dedicated craftsmen that. We have an unparalleled depth of experience and specialization in the planning, design, and development of high-profile, effective. 
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="home-page-aboutus-right-more-btn" onClick={() => setShowCoreTeam(!showCoreTeam)}>
                <ButtonArrow
                  btnArrow = {{
                    style: {alignSelf: 'flex-start'},
                    onClick: () => { }
                  }}
                  type="lower"
                  isAnimation={false}
                  title='Show Members'
                />
              </div>
            </Col>
            <Col xs={3} sm={0}></Col>
          </Row>
        </section>
        <Expanse show={showCoreTeam}>
          <div className="coreTeam-section">
            <CoreTeam isMobile={isMobile} />
            <div className="pack-up">
              <UpOutlined onClick={() => setShowCoreTeam(false)} />
            </div>
          </div>
        </Expanse>
        <section className="home-page-clients">
          <Row>
            <Col xs={3} sm={0}></Col>
            <Col xs={18} sm={24}>
              {/* <div className="home-page-clients-subtitle">PARTNERS</div> */}
              <div className="home-page-clients-title">Partners</div>
              <div className="home-page-clients-list">
                {clientsList.map((item) => (
                  <div
                    key={item.logo}
                    xs={12}
                    sm={5}
                    className="home-page-clients-list-item"
                  >
                    <img src={item.logo} alt="logo" />
                  </div>
                ))}
              </div>
            </Col>
            <Col xs={3} sm={0}></Col>
          </Row>
        </section>
        <img
          alt="mobile-map"
          src={homeMapMobile}
          className="home-page-mobile-map"
        />
          <Footer/>
      </div>
    </Fragment>
  );
}

export default Home;
