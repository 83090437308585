import React, { Component, Fragment, useEffect } from "react";
import { Row, Col, Divider } from "antd";
import "./index.less";

import homeSV from "@/assets/img/home-page/home-sv.svg";
import homeLA from "@/assets/img/home-page/home-la.svg";
import homeBeijing from "@/assets/img/home-page/home-beijing.svg";
import blurLogoWhite from "@/assets/icon/logo-400.png";
import iconPhone from "@/assets/icon/icon-phone.png";
import iconWechat from "@/assets/icon/icon-wechat.png";
import iconEmail from "@/assets/icon/icon-email.png";
import iconPosition from "@/assets/icon/icon-position.png";
import { openMeiqia } from "@/utils/utils"
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t, i18n } = useTranslation();
  const history = useHistory()
  return (
    <footer className='footer'>
      <section className="footer-wrap">
        <Row style={{ alignItems: "center" }}>
          <Col xs={0} sm={2}></Col>
          <Col xs={24} sm={5} className="footer-wrap-contacts">
            <div  className="footer-wrap-contacts-logo">
              <img src={blurLogoWhite} alt="blur-logo"/>
            </div>
           
            <div className="footer-wrap-contacts-item">
              <img src={iconPhone} alt="phone" />
              +17876096578
            </div>
            <div className="footer-wrap-contacts-item">
              <img src={iconEmail} alt="email" />
              help@nkssurf.com
            </div>
          </Col>
          <Col xs={0} sm={2}></Col>
          <Col xs={24} sm={4} className="footer-wrap-navs">
            <div onClick={() => {history.push('/case')}}>Products</div>
            <div onClick={() => {history.push('/about')}}>About</div>
          </Col>
          <Col xs={24} sm={10} className="footer-wrap-addresses">
            <Row className="footer-wrap-addresses-item">
              <Col
                xs={24}
                sm={10}
                className="footer-wrap-addresses-item-logo"
              >
                <img src={homeLA} alt="LA" />
              </Col>
              <Col xs={24} sm={14}>
                <div className="footer-wrap-addresses-item-city">
                  LOS ANGELES
                </div>
                <div className="footer-wrap-addresses-item-content">
                  <img src={iconPosition} alt="position" />
                  <div>
                    1639 Camden Ave, 
                    <br />
                    Los Anagels, CA 90025, United States
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="footer-wrap-addresses-item">
              <Col
                xs={24}
                sm={10}
                className="footer-wrap-addresses-item-logo"
              >
                <img src={homeSV} alt="SV" />
              </Col>
              <Col xs={24} sm={14}>
                <div className="footer-wrap-addresses-item-city">
                  SILICON VALLEY
                </div>
                <div className="footer-wrap-addresses-item-content">
                  <img src={iconPosition} alt="position" />
                  <div>
                    35213 Gawain ave, <br />
                    Fremont, CA 94536, United States
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={0} sm={1}></Col>
        </Row>
      </section>
      <section className="footer-beian">
        <Row>
          <Col xs={24} sm={12} className="footer-beian-company">
            ©️ Copyright 2016-2023 NKS{`  `}
          </Col>
          <Col xs={24} sm={12} className="footer-beian-icpnumber">
            {" "}
            All Rights Reserved. <span style={{color: '#cccccc'}}>琼ICP备2023000788号-3</span>
          </Col>
        </Row>
      </section>
    </footer>
  );
}

export default Footer;
