import React from 'react'
import { Row, Col } from "antd";
import fulCases1 from "@/assets/img/home-page/success-fulCases1.png";
import fulCases2 from "@/assets/img/home-page/success-fulCases2.png";
import fulCases3 from "@/assets/img/home-page/success-fulCases3.png";
import moegoLogo from "@/assets/img/home-page/moego-logo.png";
import cheeseLogo from "@/assets/img/home-page/cheese-logo.png";
import harmayLogo from "@/assets/img/home-page/harmay-logo.png";
import cheeseBg from "@/assets/img/home-page/cheese-background.png";
import harmayBg from "@/assets/img/home-page/harmay-background.png";
import MoegoBg from "@/assets/img/home-page/Moego-background.png";
import China from "@/assets/img/home-page/China.png";
import Australia from "@/assets/img/home-page/australia.png";
import cifUs from "@/assets/img/home-page/cif_us.png";
import { isMobile } from "@/utils/utils"
import "./index.less";
import { useTranslation } from 'react-i18next';

const SuccessfulCases = (props) => {
	const { t, i18n } = useTranslation();
  return (
    <div className="success-fulCases" style={{ height: isMobile() ? 'auto' : 560}}>
			<div className="case-list">
				<div className="case-li case-li1" style={{ backgroundImage: `url(${MoegoBg})` }}>
					<div className="case-li-content">
						{/* <div className="case-logo-warp">
							<img  className="case-logo-warp" alt="" src={moegoLogo} />
						</div> */}
						<img className="case-logo-warp" alt="" src={moegoLogo} />
						<div className="case-li-title">
							<div className="case-sub-title">Accessories</div>
							<div className="case-intro flex-center">
							Multiple practical accessories.
							</div>
						</div>
						<div className="picWarp picWarp1">
							<img className="picWarp-pic picWarp-pic1" src={fulCases1} alt="" />
							{/* <div className="picWarp-pie" /> */}
						</div>
						<div className="case-li-txt">
						Accessories are essential details in sports, and we always choose the best materials to make our products. This will make the exercise process safer and more efficient.
						</div>
					</div>
				</div>
				<div className="case-li case-li2" style={{ backgroundImage: `url(${harmayBg})` }}>
					<div className="case-li-content">
						{/* <div className="case-logo-warp">
							<div className="">MoeGo</div>
						</div> */}
						<img className="case-logo-warp" alt="" src={harmayLogo} />
						<div className="case-li-title">
							<div className="case-sub-title">Fashion</div>
							<div className="case-intro flex-center">
								Including T-shirts, bags, hats ……
							</div>
						</div>
						<div className="picWarp picWarp2">
							<img className="picWarp-pic picWarp-pic2" src={fulCases2} alt="" />
							{/* <div className="picWarp-pie" /> */}
						</div>
						<div className="case-li-txt">The image when surfing is very important, and a handsome appearance will make you more stylish!</div>
					</div>
				</div>
				<div className="case-li case-li3" style={{ backgroundImage: `url(${cheeseBg})` }}>
					<div className="case-li-content">
						<img className="case-logo-warp" alt="" src={cheeseLogo} />
						<div className="case-li-title">
							<div className="case-sub-title">Boards</div>
							<div className="case-intro flex-center">
								Surf · Wing Foil
							</div>
						</div>
						<div className="picWarp picWar3">
							<img className="picWarp-pic picWarp-pic3" src={fulCases3} alt="" />
							{/* <div className="picWarp-pie" /> */}
						</div>
						<div className="case-li-txt">We’ve created the most stable and user-friendly board without sacrificing the high performance feel our customers expect in a NKS product. </div>
					</div>
				</div>
			</div>
		</div>
  )
}

export default SuccessfulCases;
