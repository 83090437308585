/*
 * @Description: 
 * @Autor: xingfutan
 * @Date: 2021-07-29 17:14:09
 * @LastEditors: xingfutan
 * @LastEditTime: 2021-08-11 11:41:27
 */
import React from "react";
import "./index.less";
import { isMobile } from "@/utils/utils"
import leftArrow from "@/assets/img/home-page/left-arrow.svg"
import lowerArrow from "@/assets/img/home-page/lower-arrow.svg"

function ButtonArrow(props) {
  /**
   * @param {type} 默认是左箭头，传lower为往下
   * @param {isAnimation} 是否需要箭头动画， 默认需要
   */  
  const { type, isAnimation = true } = props; 
  return (
    <div
      className={`button-arrow ${type === 'lower' && 'button-lower-arrow'} ${isMobile() && 'button-arrow-mobile' }`}
      {...props.btnArrow}
    >
      {props.title}
      {
        isAnimation ? (
          <span className="button-arrow-line">
            <span></span>
            <span>
              <svg viewBox="64 64 896 896" focusable="false" data-icon="right" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                <path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"></path>
              </svg>
            </span>
          </span>
        ) : (
          <img className="arrow-icon" alt="" src={type === 'lower' ? lowerArrow : leftArrow} />
        )
      }
    </div>
  );
}

export default ButtonArrow;
