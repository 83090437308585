
import iconUs from "@/assets/icon/icon-us.png";
import iconKE from "@/assets/icon/icon-ke.png";
import iconChina from "@/assets/icon/icon-chn.png";
import iconAu from "@/assets/icon/icon-au.svg";
import iconEgy from "@/assets/icon/icon-egy.png";
import iconTC from "@/assets/icon/tc.svg";
import iconMini from "@/assets/icon/mini.svg";
import iconRubyOnRails from "@/assets/icon/Ruby-on-Rails.svg";
import iconReact from "@/assets/icon/React.svg";
import iconPostgreSQL from "@/assets/icon/PostgreSQL.svg";
import iconAWS from "@/assets/icon/AWS.svg";
import iconSwift from "@/assets/icon/Swift.svg";
import iconNode from "@/assets/icon/node.svg";
import iconFlutter from "@/assets/icon/flutter.svg";
import iconAndroid from "@/assets/icon/Android.svg";
import iconGolang from "@/assets/icon/Golang.svg";
import iconMySQL from "@/assets/icon/MySQL.svg";

import wapipayBg from "@/assets/img/case-page/detail/wapipay/backgroud.png";
import wapipayApp from "@/assets/img/case-page/detail/wapipay/headerApp.png";
import wapipayRequired from "@/assets/img/case-page/detail/wapipay/required.png";
import wapipaySolution from "@/assets/img/case-page/detail/wapipay/solution.png";

import swypeBg from "@/assets/img/case-page/detail/swype/backgroud.png";
import swypeApp from "@/assets/img/case-page/detail/swype/headerApp.png";
import swypeRequired from "@/assets/img/case-page/detail/swype/required.png";
import swypeSolution from "@/assets/img/case-page/detail/swype/solution.png";

import radpadBg from "@/assets/img/case-page/detail/radpad/radpad-bg.png";
import radpadMac from "@/assets/img/case-page/detail/radpad/radpad-mac.png";
import radpadRequired from "@/assets/img/case-page/detail/radpad/required.png";
import radpadSolution from "@/assets/img/case-page/detail/radpad/solution.png";

import moegoBg from "@/assets/img/case-page/detail/moego/backgroud.jpg";
import moegoHeaderMac from "@/assets/img/case-page/detail/moego/headerMac.png";
import moegoRequired from "@/assets/img/case-page/detail/moego/required.png";
import moegoSolution from "@/assets/img/case-page/detail/moego/solution.jpg";

import harmayBg from "@/assets/img/case-page/detail/harmay/backgroud.png";
import harmayApp from "@/assets/img/case-page/detail/harmay/hedaerApp.png";
import harmayRequired from "@/assets/img/case-page/detail/harmay/required.png";
import harmaySolution from "@/assets/img/case-page/detail/harmay/solution.png";

import cheeseBg from "@/assets/img/case-page/detail/cheese/backgroud.png";
import cheeseApp from "@/assets/img/case-page/detail/cheese/cheeseApp.png";
import cheeseRequired from "@/assets/img/case-page/detail/cheese/required.png";
import cheeseSolution from "@/assets/img/case-page/detail/cheese/solution.png";

import carezBg from "@/assets/img/case-page/detail/carez/backgroud.png";
import carezApp from "@/assets/img/case-page/detail/carez/carezApp.png";
import carezRequired from "@/assets/img/case-page/detail/carez/required.png";
import carezSolution from "@/assets/img/case-page/detail/carez/solution.png";

import qihuibaoBg from "@/assets/img/case-page/detail/qihuibao/backgroud.png";
import qihuibaoApp from "@/assets/img/case-page/detail/qihuibao/qihuibaoApp.png";
import qihuibaoRequired from "@/assets/img/case-page/detail/qihuibao/required.png";
import qihuibaoSolution from "@/assets/img/case-page/detail/qihuibao/solution.png";

import glassesBg from "@/assets/img/case-page/detail/glasses/backgroud.png";
import glassesApp from "@/assets/img/case-page/detail/glasses/glassesApp.png";
import glassesRequired from "@/assets/img/case-page/detail/glasses/required.png";
import glassesSolution from "@/assets/img/case-page/detail/glasses/solution.png";

import kemsBg from "@/assets/img/case-page/detail/kems/backgroud.png";
import kemsApp from "@/assets/img/case-page/detail/kems/kemsApp.png";
import kemsRequired from "@/assets/img/case-page/detail/kems/required.png";
import kemsSolution from "@/assets/img/case-page/detail/kems/solution.png";

import shopshopsBg from "@/assets/img/case-page/detail/shopshops/backgroud.png";
import shopshopsApp from "@/assets/img/case-page/detail/shopshops/shopshopsApp.png";
import shopshopsRequired from "@/assets/img/case-page/detail/shopshops/required.png";
import shopshopsSolution from "@/assets/img/case-page/detail/shopshops/solution.png";

import prismPopBg from "@/assets/img/case-page/detail/prismPop/backgroud.png";
import prismPopApp from "@/assets/img/case-page/detail/prismPop/prismPopApp.png";
import prismPopRequired from "@/assets/img/case-page/detail/prismPop/required.png";
import prismPopSolution from "@/assets/img/case-page/detail/prismPop/solution.png";

import tripalinkBg from "@/assets/img/case-page/detail/tripalink/backgroud.png";
import tripalinkApp from "@/assets/img/case-page/detail/tripalink/tripalinkApp.png";
import tripalinkRequired from "@/assets/img/case-page/detail/tripalink/required.png";
import tripalinkSolution from "@/assets/img/case-page/detail/tripalink/solution.png";

import trashausBg from "@/assets/img/case-page/detail/trashaus/backgroud.png";
import trashausApp from "@/assets/img/case-page/detail/trashaus/trashausApp.png";
import trashausRequired from "@/assets/img/case-page/detail/trashaus/required.png";
import trashausSolution from "@/assets/img/case-page/detail/trashaus/solution.png";

import rabbitBg from "@/assets/img/case-page/detail/rabbit/backgroud.png";
import rabbitApp from "@/assets/img/case-page/detail/rabbit/rabbitApp.png";
import rabbitRequired from "@/assets/img/case-page/detail/rabbit/required.png";
import rabbitSolution from "@/assets/img/case-page/detail/rabbit/solution.png";

import radpadLeft from "@/assets/img/home-page/radpad-left.png";
import radpadCenter from "@/assets/img/home-page/radpad-center.png";
import radpadRight from "@/assets/img/home-page/radpad-right.png";
import moegoIphone from "@/assets/img/case-page/moego-iphone.png";
import moegoMac from "@/assets/img/case-page/moego-mac.png";
import harmayIphone1 from "@/assets/img/case-page/harmay-iphone1.png";
import harmayIphone2 from "@/assets/img/case-page/harmay-iphone2.png";
import cheeseImg1 from "@/assets/img/case-page/cheese-1.png";
import cheeseImg2 from "@/assets/img/case-page/cheese-2.png";
import carezImg from "@/assets/img/case-page/carez.png";
import qhbMacImg from "@/assets/img/case-page/qhb-mac.png";
import qhbTopImg from "@/assets/img/case-page/qhb-1.png";
import qhbBottomImg from "@/assets/img/case-page/qhb-2.png";
import glassesImg from "@/assets/img/case-page/glasses-1.png";
import glassesAppImg from "@/assets/img/case-page/glasses-2.svg";
import kemsLeftImg from "@/assets/img/case-page/kems-left.svg";
import kemsCenterImg from "@/assets/img/case-page/kems-center.svg";
import kemsRightImg from "@/assets/img/case-page/kems-right.svg";
import shopLeftImg from "@/assets/img/case-page/shop-left.png";
import shopRightImg from "@/assets/img/case-page/shop-right.png";
import PrismPopPhoneImg from "@/assets/img/case-page/PrismPop-phone.png";
import PrismPopMacImg from "@/assets/img/case-page/PrismPop-mac.png";
import TripalinkMacImg from "@/assets/img/case-page/Tripalink-mac.png";
import TrashausLeftImg from "@/assets/img/case-page/Trashaus-left.png";
import TrashausCenterImg from "@/assets/img/case-page/Trashaus-center.png";
import TrashausRightImg from "@/assets/img/case-page/Trashaus-right.png";
import RabbitLeftImg from "@/assets/img/case-page/rabbit-left.png";
import RabbitRightImg from "@/assets/img/case-page/rabbit-right.png";

const imgKE = <img src={iconKE} alt="KE" />
const imgUs = <img src={iconUs} alt="US" />
const imgChina = <img src={iconChina} alt="US" />
const imgAu = <img src={iconAu} alt="AU" />
const imgEgy = <img src={iconEgy} alt="EGY" />
const tagTC = <span>
  <img src={iconTC} alt="" />
  <span>腾讯云</span>
</span>
const tagMini = <span>
<img src={iconMini} alt="" />
<span>小程序</span>
</span>
const tagRubyOnRails = <span>
  <img src={iconRubyOnRails} alt="Ruby on Rails" />
  <span>Ruby on Rails</span>
</span>
const tagReact = <span>
  <img src={iconReact} alt="React" />
  <span>React</span>
</span>
const tagPostgreSQL = <span>
  <img src={iconPostgreSQL} alt="PostgreSQL" />
  <span>PostgreSQL</span>
</span>
const tagAWS = <span>
  <img src={iconAWS} alt="AWS" />
  <span>AWS</span>
</span>
const tagSwift = <span>
  <img src={iconSwift} alt="Swift" />
  <span>Swift</span>
</span>
const tagAndroid = <span>
  <img src={iconAndroid} alt="Android" />
  <span>Android</span>
</span>
const tagGolang = <span>
  <img src={iconGolang} alt="Golang" />
  <span>Golang</span>
</span>
const tagMySQL = <span>
  <img src={iconMySQL} alt="MySQL" />
  <span>MySQL</span>
</span>
const tagNode = <span>
<img src={iconMySQL} alt="Node" />
<span>Node.Js</span>
</span>
const tagFlutter = <span>
<img src={iconFlutter} alt="Flutter" />
<span>Flutter</span>
</span>

export const wapipayData = {
  imgsClassName: 'wapipay',
  subtitle: 'Seat cover.',
  title: 'BARK',
  headerBgImg: wapipayBg,
  headerAppImg: [
    <img src={wapipayApp} alt="wapipayApp" />
  ],
  describe: '80% Polyester, 10% PVC, 10% polyacetal / Drawstring bag included ',
  backgroundContent: [
    'It is resistant to wear, tear, and wear, with excellent durability and service life, suitable for daily use.',
    'Made of 305 denier Oxford fabric, with a durable waterproof surface and a PVC surface on the back, it creates a sturdy seat cover that can be used in various situations.'
  ],
  requiredImg: <img src={wapipayRequired} alt="required" />,
  challenges: [
    {
      title: 'New 305 denier Oxford fabric',
    },
    {
      title: 'Lightweight yet tough',
    },
    {
      title: 'High functionality and versatility',
    },
    {
      title: 'Simple and sturdy design',
    }
  ],
  solutionContent: [
    'W62 x H140.5cm  C01(Purple)',
    'W62 x H140.5cm  C02(Black)',
  ],
  solutionImg: <img src={wapipaySolution} alt="required" />,
  carouselImgs: [
    <img src='https://resource.nkssurf.com/i/2023/07/12/p256k4.png' alt="" />,
    <img src='https://resource.nkssurf.com/i/2023/07/12/p25p77.png' alt="" />,
    <img src='https://resource.nkssurf.com/i/2023/07/12/p263m3.png' alt="" />,
    <img src='https://resource.nkssurf.com/i/2023/07/12/p26aps.png' alt="" />,
    <img src='https://resource.nkssurf.com/i/2023/07/12/p26h3r.png' alt="" />,
    <img src='https://resource.nkssurf.com/i/2023/07/12/p26nqw.png' alt="" />,
    <img src='https://resource.nkssurf.com/i/2023/07/12/p27285.png' alt="" />,
    <img src='https://resource.nkssurf.com/i/2023/07/12/p27cff.png' alt="" />,
    <img src='https://resource.nkssurf.com/i/2023/07/12/p27mny.png' alt="" />,
    <img src='https://resource.nkssurf.com/i/2023/07/12/p27y74.png' alt="" />,
    <img src='https://resource.nkssurf.com/i/2023/07/12/p28mhq.png' alt="" />,
  ],
  showImgs: [
    <img alt="snakeApp" src={wapipayApp}/>
  ]
}

export const snakeData = {
  imgsClassName: 'snake',
  subtitle: 'Time down straps.',
  title: 'SNAKE',
  headerBgImg: swypeBg,
  headerAppImg: [
    <img src={swypeApp} alt="snakeApp" />
  ],
  describe: '65% Polyester, 14% Neoprene, 13% Nylon, 7% Spandex, 1% Polyurethane',
  backgroundContent: [
    'The NKS Tie Down Straps are heavy-duty straps designed for car roof racks. ',
    'These straps are sold in sets of two 12-foot straps with 1-3/8 inch cam buckles for secure tightening. The NKS Tie Down Straps also feature a protective neoprene buckle housing.'
  ],
  requiredImg: <img src={swypeRequired} alt="required" />,
  challenges: [
    {
      title: 'Heavy duty 1 3/8" [ 35mm ] cam buckle',
    },
    {
      title: 'Integrates with NKS surf travel boardbags',
    },
    {
      title: 'Neoprene buckle housing',
    },
    {
      title: 'Carrying bag',
    }
  ],
  solutionContent: [
    '12\' [ 3m ] Set of two',
    '12\' [ 5m ] Set of two',
  ],
  solutionImg: <img src={swypeSolution} alt="required" />,
  carouselImgs: [
    <img src='https://resource.nkssurf.com/i/2023/06/29/mflvyl.png' alt="" />,
    <img src='https://resource.nkssurf.com/i/2023/06/29/mfm8it.jpg' alt="" />,
    <img src='https://resource.nkssurf.com/i/2023/06/29/mfn44j.png' alt="" />,
    <img src='https://resource.nkssurf.com/i/2023/06/29/mfnh4m.png' alt="" />,
    <img src='https://resource.nkssurf.com/i/2023/06/29/mfnnb5.png' alt="" />,
    <img src='https://resource.nkssurf.com/i/2023/06/29/mfo1p4.png' alt="" />,
    <img src='https://resource.nkssurf.com/i/2023/06/29/mfojht.png' alt="" />,
  ],
  showImgs: [
    <img alt="snakeApp" src={swypeApp}/>
  ]
}


export const vineData = {
  imgsClassName: 'vine',
  subtitle: 'Foot/Hand surf leash.',
  title: 'VINE',
  headerBgImg: radpadBg,
  headerAppImg: [
    <img src={radpadMac} alt="vineMac" />
  ],
  describe: '60%TPU / 15%Polyproplylene / 10%Neoprene / 10%Nylon / 5%Stainless',
  backgroundContent: [
    'NKS was founded with the goal of designing the ultimate surf leash, and it continues this legacy with the six-foot Comp Plus, which strikes a balance between speed and durability. Designed for surf up to the head-high range, it features the highest-quality 3/16-inch (5-millimeter) urethane Dura-Cord with speed pockets and molded ends that minimize drag and keep you tethered to your board.',
    'The molded ankle cuff padding with a new hydrophobic exterior ensures a snug-but-comfortable fit and distraction-free security through all of your maneuvers.'
  ],
  requiredImg: <img src={radpadRequired} alt="required" />,
  challenges: [
    {
      title: 'Up to a shoulder high intended use',
    },
    {
      title: '1.5" (40MM) Streamlined molded neoprene ankle cuff Posi-Lock hook & loop closure',
    },
    {
      title: 'Hydrophobic webbing material in Ankle cuff and railsaver',
    },
    {
      title: '1" (25MM) detachable railsaver',
    }
  ],
  solutionContent: [
    '6\' x 3/16" [ 1.83m x 5mm ]',
    '6\' x 1/4" [ 1.83mx 6.5mm ]',
    '4\' x 1/4" [ 1.2mx 6.5mm ]'
  ],
  solutionImg: <img src={radpadSolution} alt="required" />,
  carouselImgs: [
    <img src='https://resource.nkssurf.com/i/2023/06/27/h9whns.png' alt="" />,
    <img src='https://resource.nkssurf.com/i/2023/06/27/h9wd9r.png' alt="" />,
    <img src='https://resource.nkssurf.com/i/2023/06/27/h9wgd7.png' alt="" />,
    <img src='https://resource.nkssurf.com/i/2023/06/27/h9wo0u.png' alt="" />,
    <img src='https://resource.nkssurf.com/i/2023/06/27/h9wr97.png' alt="" />,
    <img src='https://resource.nkssurf.com/i/2023/06/27/h9wuwd.png' alt="" />,
  ],
  showImgs: [
    <img alt="vine-left" src={radpadLeft}/>,
    <img alt="vine-center" src={radpadCenter}/>,
    <img alt="vine-right" src={radpadRight}/>
  ]
}

export const moegoData = {
  imgsClassName: 'moego',
  subtitle: 'Electric air Pump',
  title: 'IJEN',
  headerBgImg: moegoBg,
  headerAppImg: [
    <img src={moegoHeaderMac} alt="moegoMac" />
  ],
  describe: 'Battery clamps / DC power cord / 6 nozzles / Air hose / Storage bag',
  backgroundContent: [
    'A heat-resistant metal motor delivers better performance than other brands’ plastic cylinders. The air pump has a 30% longer service life and is powerful enough to inflate 6 SUP boards in a row without cooling time. It is recommended to work for 20 minutes and rest for 2 minutes to extend the life of the air pump.Stylish appearance, fast inflation, excellent heat dissipation, and versatile uses.',
    'The digital screen simultaneously displays preset & real-time pressure values. The pump will automatically shut off when it reaches the preset pressure, reducing the risk of overinflation. The Power Cut Memory Function automatically recalls the previously set pressure for the next inflation. Two pressure units to choose from.',
    'With the integrated storage compartment and included storage bag, the pump is very convenient to use and carry.Package includes the SUP pump, DC power cord, battery clamps, air hose, storage bag, 6 nozzles, replacement fuse, and user manual.'
  ],
  requiredImg: <img src={moegoRequired} alt="required" />,
  challenges: [
    {
      title: 'Metal cylinder & ultra-powerful performance',
    },
    {
      title: '7.5 Inches ultra-large screen',
    },
    {
      title: 'Rapid inflation & deflation',
    },
    {
      title: 'Two smart modes',
    },
    {
      title: 'Easy storage & Simple operation',
    },
  ],
  solutionContent: [
    'Weight：4.12 Pounds',
    'Air Flow Capacity：360 LPM',
    'Maximum Pressure：20 Pound per Square Inch',
    'L x W x H：7.5"L x 4"W x 11"H',
  ],
  solutionImg: <img src={moegoSolution} alt="solution" />,
  carouselImgs: [
    <img src='https://resource.nkssurf.com/i/2023/07/14/h8ptrd.jpg' alt="" />,
    <img src='https://resource.nkssurf.com/i/2023/07/14/h8q4rl.jpg' alt="" />,
    <img src='https://resource.nkssurf.com/i/2023/07/14/h8zgmd.png' alt="" />,
    <img src='https://resource.nkssurf.com/i/2023/07/14/h8zv1u.jpg' alt="" />,
    <img src='https://resource.nkssurf.com/i/2023/07/14/h904oc.png' alt="" />,
    <img src='https://resource.nkssurf.com/i/2023/07/14/h90igt.jpg' alt="" />,
    <img src='https://resource.nkssurf.com/i/2023/07/14/h90qwt.jpg' alt="" />,
  ],
  showImgs: [
    <img alt="moego-left" src={moegoIphone}/>,
    <img alt="moego-right" src={moegoMac}/>,
  ]
}


export const cicadaPData = {
  imgsClassName: 'cicadaP',
  subtitle: 'Wing foil board.',
  title: 'CICADA - PRO',
  headerBgImg: harmayBg,
  headerAppImg: [
    <img src={harmayApp} alt="cicadaPMac" />
  ],
  describe: 'Full carbon construction / Day board bag included',
  backgroundContent: [
    'The board is a great high performance daily driver that won’t leave you stranded when the wind backs off.',
    'It is has minimal swing weight and maximum volume. Depending on the rider\'s weight, this can be a high volume sinker or knee start board.'
  ],
  requiredImg: <img src={harmayRequired} alt="required" />,
  challenges: [
    {
      title: 'Up to a shoulder high intended use',
    },
    {
      title: '1.5" (40MM) Streamlined molded neoprene ankle cuff Posi-Lock hook & loop closure',
    },
    {
      title: 'Hydrophobic webbing material in Ankle cuff and railsaver',
    },
    {
      title: '1" (25MM) detachable railsaver',
    }
  ],
  solutionContent: [
    '75L [ 4’10” x 26” x 4.5” ]  13.75 lbs. (6.2 kg)',
    '44L [ 4’4” x 21.5” x 3.5” ]  9.5 lbs. (4.3 kg)',
    '34L [ 4’6” x 20.25” x 2.75” ]  8.0 lbs. (3.6 kg)',
    '26L [ 4’0” x 20” x 2.5” ]  7.5 lbs. (3.4 kg)',
  ],
  solutionImg: <img src={harmaySolution} alt="required" />,
  carouselImgs: [
    <img src='https://resource.nkssurf.com/i/2023/06/27/glftbe.png' alt="" />,
    <img src='https://resource.nkssurf.com/i/2023/06/27/glg0bg.png' alt="" />,
    <img src='https://resource.nkssurf.com/i/2023/06/27/glgok2.png' alt="" />,
    <img src='https://resource.nkssurf.com/i/2023/06/27/glgwma.png' alt="" />,
    <img src='https://resource.nkssurf.com/i/2023/06/27/glh8np.png' alt="" />,
    <img src='https://resource.nkssurf.com/i/2023/06/27/glhmnj.png' alt="" />,
    <img src='https://resource.nkssurf.com/i/2023/06/27/gzsw0m.png' alt="" />,
   
  ],
  showImgs: [
    <img alt="cicadaP-left" src={harmayIphone1}/>,
    <img alt="cicadaP-right" src={harmayIphone2}/>,
  ]
}

export const cicadaLData = {
  imgsClassName: 'cicadaL',
  subtitle: 'Wing foil board.',
  title: 'CICADA - LITE',
  headerBgImg: cheeseBg,
  headerAppImg: [
    <img src={cheeseApp} alt="cicadaL" />
  ],
  describe: 'Full carbon construction / Day board bag included',
  backgroundContent: [
    'Without being too thick and cumbersome. The rail line and bottom contours allow the board to quickly release out of the water, so you can focus on flying.',
    'Our Wing Foil Board comes shipped in our premium day bag for protection and hauling.'
  ],
  requiredImg: <img src={cheeseRequired} alt="required" />,
  challenges: [
    {
      title: '1-piece foam pad',
    },
    {
      title: 'Front and rear strap inserts',
    },
    {
      title: '10.75” mast tracks',
    },
    {
      title: 'Handle on bottom',
    }
  ],
  solutionContent: [
    '105L [ 5’2” x 30” x 5” ] 17.5 lbs. (7.9 kg)',
    '75L [ 4’10” x 26” x 4.5” ]  13.75 lbs. (6.2 kg)',
    '55L [ 4’6” x 22.5” x 4” ]  11 lbs. (5.0 kg)',
  ],
  solutionImg: <img src={cheeseSolution} alt="required" />,
  carouselImgs: [
    <img src='https://resource.nkssurf.com/i/2023/06/21/ppvbvi.png' alt="" />,
    <img src='https://resource.nkssurf.com/i/2023/06/21/ppwfsh.png' alt="" />,
    <img src='https://resource.nkssurf.com/i/2023/06/21/ppwiwc.png' alt="" />,
    <img src='https://resource.nkssurf.com/i/2023/06/21/ppxj35.png' alt="" />,
    <img src='https://resource.nkssurf.com/i/2023/06/21/ppy6ox.png' alt="" />,
    <img src='https://resource.nkssurf.com/i/2023/06/21/ppydxb.png' alt="" />,
    <img src='https://resource.nkssurf.com/i/2023/06/21/ppyjfz.png' alt="" />,
    <img src='https://resource.nkssurf.com/i/2023/06/21/ppyffs.png' alt="" />,
    <img src='https://resource.nkssurf.com/i/2023/06/21/ppzh1h.png' alt="" />,
  ],
  showImgs: [
    <img alt="cicadaL-left" src={cheeseImg1}/>,
    <img alt="cicadaL-right" src={cheeseImg2}/>,
  ]
}

export const carezData = {
  imgsClassName: 'carez',
  subtitle: 'Windsurf mast.',
  title: 'HARDCORE',
  headerBgImg: carezBg,
  headerAppImg: [
    <img src={carezApp} alt="hardcore" />
  ],
  describe: '90% carbon / fiberglass / mast bag',
  backgroundContent: [
    'An entirely new mast line - the result of a different approach to the issue. This is not a "slightly worse 100% carbon" type mast. HARDCORE was created to cope with the toughest conditions and rough treatment. It will withstand the longest trips, the most intense sessions, and countless falls and beatings. It is the perfect mast for practising new tricks when the number of successful attempts is smaller than the number of falls.',
    'The appropriately selected Constant Curve bending characteristic ensures that it works with most sails on the market, without worrying about their performance and behaviour on the water. To achieve maximum durability, designers added more layers of carbon, making the mast thicker and more resistant to impacts.'
  ],
  requiredImg: <img src={carezRequired} alt="required" />,
  challenges: [
    {
      title: 'Compatible with most sails on the market',
    },
    {
      title: 'Constant Curve (CC) bend characteristics',
    },
    {
      title: '90% carbon',
    },
    {
      title: 'Special coating prevents the boom from sliding',
    },
    {
      title: 'The hard EVA cork reduces the risk of the mast getting stuck',
    }
  ],
  solutionContent: [
    '370cm [ RDM ] 1150g',
    '400cm [ RDM ] 1380g / 400cm [ SDM ] 1450g',
    '430cm [ RDM ] 1550g / 430cm [ SDM ] 1600g',
    '460cm [ RDM ] 1670g / 460cm [ SDM ] 1840g',
    '490cm [ SDM ] 1920g',
    '520cm [ SDM ] 2300g',
  ],
  solutionImg: <img src={carezSolution} alt="required" />,
  carouselImgs: [
    <img src='https://resource.nkssurf.com/i/2023/08/28/qtau70.png' alt="" />,
    <img src='https://resource.nkssurf.com/i/2023/08/28/qtb9x3.jpg' alt="" />,
    <img src='https://resource.nkssurf.com/i/2023/08/28/qtbkqy.jpg' alt="" />,
    <img src='https://resource.nkssurf.com/i/2023/08/28/qtc0i9.jpg' alt="" />,
    <img src='https://resource.nkssurf.com/i/2023/08/28/qtcatm.jpg' alt="" />,
  ],
  showImgs: [
    <img alt="carez" src={carezImg}/>,
  ]
}
export const qihuibaoData = {
  imgsClassName: 'qihuibao',
  subtitle: '智能政策匹配与自动化申报平台',
  title: '企惠宝',
  headerBgImg: qihuibaoBg,
  headerAppImg: [
    <img src={qihuibaoApp} alt="" />
  ],
  countryIcons: [imgChina],
  describe: '政策申报',
  technologyTags:[
    tagTC, tagReact, tagMini, tagAWS, tagSwift, tagGolang
  ],
  backgroundContent: [
    '企惠宝应用人工智能与大数据、为中小微科技企业打造的智能政策匹配与自动化申报平台。该平台帮助企业进行智能推荐、一键提交表单和全流程跟踪服务，实现了更高效、精准和安全的政策扶持申请。'
  ],
  requiredTitle: '为企业提供智能化政策匹配与自动化申报服务',
  requiredContent: '通过数据挖掘与企业政策知识图谱构建，为企业提供智能化政策匹配与自动化申报服务，打通企业与政府之间的壁垒，为企业申报政府补贴提供高效便捷的途径。与传统中介相比，企惠宝平台提高了50%以上申报效率，费用低至传统中介20%。',
  requiredImg: <img src={qihuibaoRequired} alt="required" />,
  challenges: [
    {
      title: '数据挖掘难度大',
      content: '政府政策发布不确定因素多、发布渠道广。全国每年有上万条政策信息由数百个政府网站发布，这对数据采集与挖掘提出了很高的要求。'
    },
    {
      title: '政策图谱要求高',
      content: '政府政策内容相对复杂，需要通过自然语言处理技术（NLP）提取有效信息并进行创建政策知识图谱，难度较大。'
    },
    {
      title: '精准匹配困难多',
      content: '根据企业工商信息、融资信息、知识产权等信息提取企业特性，并根据政策知识图谱进行精准匹配，对算法模型的开发具有很大挑战。'
    },
  ],
  solutionTitle: '全国政策自动化匹配与申报服务平台网站',
  solutionContent: [
    '企惠宝为美华柏乐自有产品，团队完成了从产品构思、市场分析、交互设计到软件开发的全流程。该产品已与北京海淀创业园、WeWork等众创空间达成合作，为数千家中小微科技企业提供政策匹配与高新申报SaaS服务。',
    '通过数据挖掘与深度学习技术，构建了具有自主知识产权的全国政策与企业知识图谱，完成了全国政策自动化匹配与申报服务平台。通过运用数据技术，数据收集、数据分析、智能匹配，对潜在政策信息进行分析及对应匹配。为申报政府扶持的企业扩大信息量、减少流程成本。',
  ],
  solutionImg: <img src={qihuibaoSolution} alt="solution" />,
  resultContent: [
    '美华柏乐团队自主开发了Web应用和后台管理系统。做到了全国政策实时监控和采集。平台目前拥有超过8万政策扶持、300万余企业工商知识产权和投融资数据。帮助企业更高效的搜寻匹配政策，促进更积极的创业环境。',
    '该产品已与北京海淀创业园、WeWork等众创空间达成合作。'
  ],
  carouselImgs: [
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Qihuibao/p1.png' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Qihuibao/p2.jpg' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Qihuibao/p3.jpg' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Qihuibao/p4.jpg' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Qihuibao/p5.jpg' alt="" />,
  ],
  showImgs: [
    <img alt="qihuibao-mac" src={qhbMacImg}/>,
    <img alt="qihuibao-top" src={qhbTopImg}/>,
    <img alt="qihuibao-bottom" src={qhbBottomImg}/>,
  ]
}

export const glassesData = {
  imgsClassName: 'glasses',
  subtitle: '城市管理眼镜APP',
  title: '中海智能执法眼镜',
  headerBgImg: glassesBg,
  headerAppImg: [
    <img src={glassesApp} alt="" />
  ],
  countryIcons: [imgChina],
  describe: '政府职能',
  technologyTags:[
    tagTC, tagReact, tagMini, tagAWS, tagSwift, tagMySQL, tagAndroid
  ],
  backgroundContent: [
    '中海智能眼镜与其APP是海淀区“城市大脑”的项目之一。“城市大脑”是以互联网为基础设施，基于城市资源，对其进行全局的即时分析和指挥，从而促进城市管理领域的执法工作。'
  ],
  requiredTitle: '开发智能执法眼镜第一时间直播取证功能，协助城市管理工作',
  requiredContent: '根据城市管理领域智能化、现代化的需求，城管局需开发智能执法眼镜，将人工智能引入城市执法工作，执法人员执行任务时通过佩戴智能眼镜，实现实时直播、第一时间取证的效果。佩戴期间记录的图片及视频将上传云端，方便城市执法人员及时取证和回放，实现智能执法的迭代升级。',
  requiredImg: <img src={glassesRequired} alt="required" />,
  challenges: [
    {
      title: '硬件调试困难大',
      content: '智能眼镜采用800万像素的UVC摄像头，如何通过APP实时采集UVC数据流并转换为RMTP数据流进行推流直播难度很大。'
    },
    {
      title: '直播延时低',
      content: '由于产品应用场景为城管执法现场，对直播画面质量与流畅度要求很高，同时又要达到小于2秒的直播延迟，对APP与后台直播性能要求很高。'
    },
    {
      title: '系统逻辑复杂',
      content: 'APP需要满足多部门多用户的权限管理与直播配置，方便部门领导观看一线执法人员的实时画面，并可以进行实时文字与图片交流。'
    },
  ],
  solutionTitle: '定制化Android APP和整套后台管理系统',
  solutionContent: [
    '针对其需求，美华柏乐为其定制开发了其Android App以及后台管理系统。智能眼镜连接到手机端后，进行直播、储存视频到云端，保证实时图像的真实性，以防止篡改。',
    '该应用通过协助执法人员第一视角直播，对其突发情况进行记录、分析和管理，从而实现对城市的协同指挥。做到60秒接报响应，3分钟事件报传，通过语音识别、自然语义分析等AI手段，实现突发事件处置全过程可追溯。',
  ],
  solutionImg: <img src={glassesSolution} alt="solution" />,
  resultContent: [
    '美华柏乐团队开发并按时交付APP和后台管理系统，为政府职能领域提供了强有力的技术支撑。搭建的Android APP以及后台管理系统为城市执法人员提供了视频直播、储存、数据加密、实时定位等功能。提升了监控和取证效率、促进了城市管理领域的执法工作。',
    '中海智能眼镜APP目前处于内测阶段，已在北京市海淀区多个区域进行试用，执法人员佩戴智能执法眼镜进行执法实时直播及执法记录云端存储，推进了执法工作智能化、现代化改革。'
  ],
  carouselImgs: [
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Zhonghai/p1.png' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Zhonghai/p2.png' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Zhonghai/p3.png' alt="" />,
  ],
  showImgs: [
    <img alt="glasses" src={glassesImg}/>,
    <img alt="glasses-app" src={glassesAppImg}/>,
  ]
}

export const kemsData = {
  imgsClassName: 'kems',
  subtitle: '黑科技健身线上线下一体化小程序及CRM管理系统',
  title: 'K-EMS Studio',
  headerBgImg: kemsBg,
  headerAppImg: [
    <img src={kemsApp} alt="" />
  ],
  countryIcons: [imgChina],
  describe: '健身,电商',
  technologyTags:[
    tagTC, tagReact, tagMini, tagAWS, tagSwift, tagPostgreSQL
  ],
  backgroundContent: [
    'K-EMS Studio是中国专业EMS科技健身连锁品牌。全国拥有超过200家线下健身会所。K-EMS通过将源自美国宇航局NASA的EMS技术融入健身，把真正核心的健康理念带给数十万会员。'
  ],
  requiredTitle: '企业需要开发一套B端的后台管理系统和C端的小程序满足融资需求',
  requiredContent: '随着公司业务迅速扩张以及融资需要，K-EMS需要开发一整套线上信息系统。包括to C端的APP或小程序，方便会员线上买课、预约以及查看训练数据，to B端的后台管理系统、线上营销系统以及教练排课系统，方便公司进行一体化线上管理及运营。',
  requiredImg: <img src={kemsRequired} alt="required" />,
  challenges: [
    {
      title: '软硬件协作联动',
      content: '会员通过微信小程序约课后，需要在门店扫码签到，同时与教练共同扫码启动机器进行续联，所有数据实时上传至后台管理系统，方便运营人员实时跟踪。'
    },
    {
      title: '智能训练方案',
      content: '会员训练时，智能硬件实时采集用户身体数据，包括心率、体脂率、肌肉含量等，统一上传到后台系统，后台结合用户性别、年龄、体重等因素为会员制定个性化训练方案。'
    },
    {
      title: '管理难度大',
      content: 'K-EMS Studio全国线下门店超过200家，拥有数十万会员、数百位工作人员，系统数据量大，管理困难。'
    },
  ],
  solutionTitle: '一体化的技术解决方案，包含微信小程序、后台管理系统、财务系统、排课系统以及线上营销系统',
  solutionContent: [
    '针对客户需求与行业竞品分析，美华柏乐为K-EMS提供了一体化的技术解决方案。包括设计与开发微信小程序、后台管理系统、财务系统、排课系统以及线上营销系统。',
    '顾客通过使用微信小程序，可以进行购课、约课、签到等操作。在每次训练结束后可以在小程序端查看自己的训练数据，包括训练强度、消耗卡路里数、体脂率、肌肉含量等。而公司运营人员与门店教练可以通过后台管理系统实时了解课程销售情况以及进行排课、约课等操作。',
  ],
  solutionImg: <img src={kemsSolution} alt="solution" />,
  resultContent: [
    '项目总共分三期进行开发，美华柏乐每一期都按时交付，为客户的融资需求提供了强有力的技术支撑。系统如期上线后，K-EMS小程序因其丰富的功能以及流畅的用户体验，在短时间获得了大量顾客好评，同时也增加了投资人信心。',
    '最终K-EMS母公司顺利获得数千万人民币Pre-A轮融资，投后估值达到3亿人民币。全国200多家门店、数十万顾客使用该小程序进行购课、预约、锻炼等操作。'
  ],
  carouselImgs: [
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/KemsStudio/p1.png' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/KemsStudio/p2.png' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/KemsStudio/p3.png' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/KemsStudio/p4.png' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/KemsStudio/p5.png' alt="" />,
  ],
  showImgs: [
    <img alt="kems-left" src={kemsLeftImg}/>,
    <img alt="kems-center" src={kemsCenterImg}/>,
    <img alt="kems-right" src={kemsRightImg}/>,
  ]
}

export const shopshopsData = {
  imgsClassName: 'shopshops',
  subtitle: 'Shopshops哪逛',
  title: 'Shopshops哪逛',
  headerBgImg: shopshopsBg,
  headerAppImg: [
    <img src={shopshopsApp} alt="" />
  ],
  countryIcons: [imgChina],
  describe: '海淘,电商',
  technologyTags:[
    tagReact, tagMini, tagAWS, tagSwift, tagPostgreSQL
  ],
  backgroundContent: [
    'ShopShops是集旅游购物指南、时尚分享社区、实体店跨境直购等功能于一体的跨境电商平台，旨在为用户提供最直接、最便捷的交互式跨境购物体验。ShopShops致力于建立境外商户与全球消费者之间的连接，让消费者摆脱代购困扰，一站直达海外精品店。通过实时策展、交互设计等技术，用户可以进行虚拟购物，获得最真实的一手信息。'
  ],
  requiredTitle: '因业务扩张，公司需要构建一整套完整的电商系统',
  requiredContent: '根据公司业务需要，ShopShops需要开发一套美国电商数据整合系统，通过API接口形式，为其国内直播APP提供美国商品价格、库存、品类等信息的实时查询功能，同时支持用户下单、锁定库存、跨境支付等操作。',
  requiredImg: <img src={shopshopsRequired} alt="required" />,
  challenges: [
    {
      title: '同质化竞争激烈',
      content: '美国已经有许多较为成熟的线上租房平台，目标客户与RadPad存在高度重叠，客户可能对旧平台产生依赖或者忠诚度，在此市场背景下建立独特优势并获得客户信任存在挑战。'
    },
    {
      title: '支付系统革新阻力大',
      content: '美国市场高度依赖借记卡支付或者其他传统支付方式，尤其在传统的房地产行业没有使用移动支付系统进行房租支付的先例。因此市面上没有可以参考的成功案例，需要独立搭建整套房租支付体系。'
    },
    {
      title: '第三方平台整合难',
      content: 'RadPad需要整合数十个第三方平台，且第三方平台发展程度不一，有的是成熟的平台而有的则是新兴平台，因此完成所有第三方平台的对接，难度较大，耗时较长。'
    },
    {
      title: '用户数量多，设计要求高',
      content: '有超过上千万的用户使用RadPad平台，为了确保提供流畅的用户体验，对系统架构设计、数据库优化等都有极高的要求。'
    },
  ],
  solutionTitle: '整套部署在美国AWS的电商数据整合系统和直播购物APP',
  solutionContent: [
    '美华柏乐为其打造了整套部署在美国AWS的电商数据整合系统。该系统与全球超过100个合作商户数据系统进行无缝对接，实时获取商家最新商品库存以及价格等信息，并可在线完成库存锁定、下单以及跨境支付等操作。',
    '国内用户通过其直播App，可以实时观看平台主播在北美线下门店进行的直播，与主播实时互动交流、评论，并选择满意的产品下单使用支付宝或微信支付购买。',
  ],
  solutionImg: <img src={shopshopsSolution} alt="solution" />,
  resultContent: [
    '项目总共分三期进行开发，美华柏乐团队与ShopShops技术团队协同开发，每一期都按时交付，为客求提供了强有力的技术支撑。',
    '系统如期上线后。截至目前，ShopShops已完成2000万美元融资，与150多个品牌和零售商建立了合作关系；从奢侈品设计师到多品牌商店再到独立零售商与全球1000家零售商合作；为来自纽约，洛杉矶和迈阿密等城市的中国客户提供数千场直播购物活动。'
  ],
  carouselImgs: [
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Shopshops/p1.png' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Shopshops/p2.png' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Shopshops/p3.png' alt="" />,
  ],
  showImgs: [
    <img alt="shop-left" src={shopLeftImg}/>,
    <img alt="shop-right" src={shopRightImg}/>,
  ]
}

export const prismPopData = {
  imgsClassName: 'prismPop',
  subtitle: '美妆社区分享平台APP',
  title: 'PrismPop',
  headerBgImg: prismPopBg,
  headerAppImg: [
    <img src={prismPopApp} alt="" />
  ],
  countryIcons: [imgUs],
  describe: '美妆电商',
  technologyTags:[
    tagRubyOnRails, tagReact, tagPostgreSQL, tagAWS, tagSwift, tagAndroid
  ],
  backgroundContent: [
    'Prismpop是一家在线美妆社区分享平台，致力于帮助用户找到个性化的产品，被称为“美国版小红书”。平台汇聚了上千位欧美美妆达人的数万条短视频。通过任务分发、积分兑奖、小样赠送等营销活动，提高美妆博主的参与热情。用户可以根据兴趣和独特需求探索个性化的美容产品，分享护肤品，交流使用心得。'
  ],
  requiredTitle: '产品需要一套定制化的美妆社区互动系统',
  requiredContent: '根据公司业务需要，ShopShops需要开发一套社区互动系统，包括了iOS APP、后台API接口以及智能推荐系统。',
  requiredImg: <img src={prismPopRequired} alt="required" />,
  challenges: [
    {
      title: '同质化竞争激烈',
      content: '美国已经有许多较为成熟的线上租房平台，目标客户与RadPad存在高度重叠，客户可能对旧平台产生依赖或者忠诚度，在此市场背景下建立独特优势并获得客户信任存在挑战。'
    },
    {
      title: '支付系统革新阻力大',
      content: '美国市场高度依赖借记卡支付或者其他传统支付方式，尤其在传统的房地产行业没有使用移动支付系统进行房租支付的先例。因此市面上没有可以参考的成功案例，需要独立搭建整套房租支付体系。'
    },
    {
      title: '第三方平台整合难',
      content: 'RadPad需要整合数十个第三方平台，且第三方平台发展程度不一，有的是成熟的平台而有的则是新兴平台，因此完成所有第三方平台的对接，难度较大，耗时较长。'
    },
    {
      title: '用户数量多，设计要求高',
      content: '有超过上千万的用户使用RadPad平台，为了确保提供流畅的用户体验，对系统架构设计、数据库优化等都有极高的要求。'
    },
  ],
  solutionTitle: '定制化iOS APP、后台API接口以及智能推荐系统',
  solutionContent: [
    '美华柏乐为其设计开发了定制化iOS APP、后台API接口以及智能推荐系统。',
    '智能推荐系统可以根据不同的内容、关键词、受众偏好等将广告展示给更有意向的用户。用户可以在IOS App平台进行好物分享、美妆测评、美妆发现、用户交流等活动。',
  ],
  solutionImg: <img src={prismPopSolution} alt="solution" />,
  resultContent: [
    '项目总共分三期进行开发。智能推荐系统为用户提供更贴合需求的内容，面向不同阶段、不同身份的群体提供针对性的推荐，提高用户体验感，唤起用户的消费需求。',
    '目前Prismpop已完成由美国红杉资本领投的数百万美元种子轮融资。'
  ],
  carouselImgs: [
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/PrismPop/p1.jpg' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/PrismPop/p2.jpg' alt="" />,
  ],
  showImgs: [
    <img alt="PrismPop-mac" src={PrismPopMacImg}/>,
    <img alt="PrismPop-phone" src={PrismPopPhoneImg}/>,
  ]
}

export const tripalinkData = {
  imgsClassName: 'tripalink',
  subtitle: '北美多元共居社区WEB应用',
  title: 'Tripalink',
  headerBgImg: tripalinkBg,
  headerAppImg: [
    <img src={tripalinkApp} alt="" />
  ],
  countryIcons: [imgUs],
  describe: '房屋租赁',
  technologyTags:[
    tagRubyOnRails, tagReact, tagPostgreSQL, tagAWS, tagSwift, tagAndroid
  ],
  backgroundContent: [
    'Tripalink是致力于打造“Co-living”居住模式的租房平台。Tripalink与房地产开发商、经纪人、房主和物业管理公司合作，为美国的千禧一代打造专属的共享生活体验，成为最受当下美国千禧一代及青年白领欢迎的租房平台。'
  ],
  requiredTitle: '因业务扩张，公司需要一套网上看房系统和后台管理系统',
  requiredContent: '随着公司业务扩张以及技术需要，Tripalink需要开发一套网上看房系统和后台管理系统，方便用户能在网上更高效、更精准地找到合适房源并完成线上签约。',
  requiredImg: <img src={tripalinkRequired} alt="required" />,
  challenges: [
    {
      title: '同质化竞争激烈',
      content: '美国已经有许多较为成熟的线上租房平台，目标客户与RadPad存在高度重叠，客户可能对旧平台产生依赖或者忠诚度，在此市场背景下建立独特优势并获得客户信任存在挑战。'
    },
    {
      title: '支付系统革新阻力大',
      content: '美国市场高度依赖借记卡支付或者其他传统支付方式，尤其在传统的房地产行业没有使用移动支付系统进行房租支付的先例。因此市面上没有可以参考的成功案例，需要独立搭建整套房租支付体系。'
    },
    {
      title: '第三方平台整合难',
      content: 'RadPad需要整合数十个第三方平台，且第三方平台发展程度不一，有的是成熟的平台而有的则是新兴平台，因此完成所有第三方平台的对接，难度较大，耗时较长。'
    },
    {
      title: '用户数量多，设计要求高',
      content: '有超过上千万的用户使用RadPad平台，为了确保提供流畅的用户体验，对系统架构设计、数据库优化等都有极高的要求。'
    },
  ],
  solutionTitle: '定制化线上看房、预约平台以及后台管理系统',
  solutionContent: [
    '美华柏乐根据其需求，量身打造了线上看房、预约平台以及后台管理系统。为用户提供更真实直观的房源，打消用户租房顾虑，提供更加便捷高效的线上租赁服务，为客户实现规模发展提供助力。',
  ],
  solutionImg: <img src={tripalinkSolution} alt="solution" />,
  resultContent: [
    '美华柏乐团队为Tripalink增加新业务模块，交付了整套后台支付交易系统和线上看房系统，为用户提供更真实直观的房源。',
    '目前Tripalink获得超过1800万美元融资。自2016年成立以来，Tripalink在三年时间实现房源数量从100到3000的增长，覆盖城市从洛杉矶扩张到西雅图、费城、旧金山等10大城市，服务群体也从留学生扩展到全美的青年学生及白领。'
  ],
  carouselImgs: [
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Tripalink/p1.jpg' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Tripalink/p2.jpg' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Tripalink/p3.jpg' alt="" />,
  ],
  showImgs: [
    <img alt="Tripalink-mac" src={TripalinkMacImg}/>,
  ]
}

export const trashausData = {
  imgsClassName: 'trashaus',
  subtitle: '垃圾溯源小程序',
  title: 'Trashaus',
  headerBgImg: trashausBg,
  headerAppImg: [
    <img src={trashausApp} alt="" />
  ],
  countryIcons: [imgChina],
  describe: '环保科技',
  technologyTags:[
    tagTC, tagReact, tagMini, tagAWS, tagSwift, tagPostgreSQL
  ],
  backgroundContent: [
    'Trashaus的企业客户可以通过SaaS系统创建、编辑多种类溯源产品，并实现“一物一码”功能。用户在购买由回收原材料制作的商品（如环保手机壳）时，通过微信扫码即可打开溯源小程序，查看产品的“前世今生”，了解环保产品的生产流程。'
  ],
  requiredTitle: '公司需要开发移动平台帮助企业处理可再生废弃物并实时追踪去向',
  requiredContent: 'Trashaus的企业客户可以通过SaaS系统创建、编辑多种类溯源产品，并实现“一物一码”功能。用户在购买由回收原材料制作的商品（如环保手机壳）时，通过微信扫码即可打开溯源小程序，查看产品的“前世今生”，了解环保产品的生产流程。',
  requiredImg: <img src={trashausRequired} alt="required" />,
  challenges: [
    {
      title: '同质化竞争激烈',
      content: '美国已经有许多较为成熟的线上租房平台，目标客户与RadPad存在高度重叠，客户可能对旧平台产生依赖或者忠诚度，在此市场背景下建立独特优势并获得客户信任存在挑战。'
    },
    {
      title: '支付系统革新阻力大',
      content: '美国市场高度依赖借记卡支付或者其他传统支付方式，尤其在传统的房地产行业没有使用移动支付系统进行房租支付的先例。因此市面上没有可以参考的成功案例，需要独立搭建整套房租支付体系。'
    },
    {
      title: '第三方平台整合难',
      content: 'RadPad需要整合数十个第三方平台，且第三方平台发展程度不一，有的是成熟的平台而有的则是新兴平台，因此完成所有第三方平台的对接，难度较大，耗时较长。'
    },
    {
      title: '用户数量多，设计要求高',
      content: '有超过上千万的用户使用RadPad平台，为了确保提供流畅的用户体验，对系统架构设计、数据库优化等都有极高的要求。'
    },
  ],
  solutionTitle: '微信溯源小程序以及统一的SaaS后台管理系统',
  solutionContent: [
    '随着公司业务扩张以及技术需要，Trashaus需要开发微信溯源小程序以及统一的SaaS后台管理系统，帮助消费者解决包装对环境影响的顾虑，同时提高品牌的用户粘性。',
  ],
  solutionImg: <img src={trashausSolution} alt="solution" />,
  resultContent: [
    '美华柏乐交付了多个品牌小程序和统一的SaaS后台管理系统，有助于解决消费者对外卖废弃包装的环境影响产生的顾虑，在“垃圾”中创造价值，帮助品牌和消费者消费后的市场沟通联系。',
  ],
  carouselImgs: [
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Trashaus/P1.jpg' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Trashaus/p2.png' alt="" />,
  ],
  showImgs: [
    <img alt="Trashaus-left" src={TrashausLeftImg}/>,
    <img alt="Trashaus-center" src={TrashausCenterImg}/>,
    <img alt="Trashaus-right" src={TrashausRightImg}/>,
  ]
}

export const rabbitData = {
  imgsClassName: 'rabbit',
  subtitle: '生鲜线上零售app',
  title: 'Rabbit',
  headerBgImg: rabbitBg,
  headerAppImg: [
    <img src={rabbitApp} alt="" />
  ],
  countryIcons: [imgEgy],
  describe: '生鲜电商',
  technologyTags:[
    tagTC, tagReact,tagNode, tagAWS, tagFlutter
  ],
  backgroundContent: [
    'Rabbit是埃及版的“美团外卖”项目，主打超市生鲜20分钟配送。埃及用户可以在APP上浏览附近超市的商品，在线下单，享受优惠折扣，商家在20分钟内完成送货上门服务。让消费者足不出户即可享受在线购买和极速配送服务。'
  ],
  requiredTitle: '产品需要开发一整套电商+配送以及后台管理的APP和CRM系统',
  requiredContent: 'Rabbit希望综合“超市+配送”功能，在埃及首创实体超市与线上电商结合的商业模式。以突出经营生鲜、强化物流配送为主要特色，通过打通线上与线下，并多引流到线上，再通过店仓一体，从而实现极致的生鲜购物和物流配送体验的目标。',
  requiredImg: <img src={rabbitRequired} alt="required" />,
  challenges: [
    {
      title: '第三方程序整合复杂',
      content: 'Rabbit与Googlemap、Keycloak、Softec等多个第三方程序合作，共享并传输用户和订单数据，保证与各个程序对接无误，耗时长，工作量大。'
    },
    {
      title: '搭载多个硬件工作',
      content: 'APP集成ZEBRA手持扫码设备和EPSON热敏打印机协同完成商品拣选入库等工作，需要连接硬件，进行真机调试。'
    },
    {
      title: '产品商业模式创新',
      content: '客户采用线上APP和线下自营超市覆盖生鲜食品的一体化商业模式，利用实体门店为电商引流，是在埃及当地的创新尝试。'
    },
    {
      title: '支持英语和阿拉伯语双语版本',
      content: '整个平台支持英语和阿拉伯语切换，需要先开发英语版app，再进行特殊处理，转换为阿拉伯语版本。'
    },
  ],
  solutionTitle: 'RabbitMart和RabbitPicker，定制化 APP和CRM管理系统',
  solutionContent: [
    '针对埃及当地配送服务业务场景以及Rabbit线下自营超市商业特点，美华柏乐为其开发了完整的iOS及Android APP，RabbitMart和Picker以及后台ERP及CRM管理系统。整个平台支持英语及阿拉伯语双语言切换。',
    'RabbitMart通过技术驱动产业链升级，为当地用户提供便捷、高效的线上线下消费体验。',
    'RabbitPicker深度结合实际使用场景，最大化提升内部人员工作效率。结合管理后台，使门店经理和工作人员多方联动协作，保证工作流畅性，有效提高超市门店管理水平。',
  ],
  solutionImg: <img src={rabbitSolution} alt="solution" />,
  resultContent: [
    '美华柏乐团队交付了包括RabbitMart和RabbitPicker两款定制化iOS & Android APP和整套后台管理系统。 利用实体门店为App吸引用户源、积累客户，同时搭载硬件设备和ERP、CRM管理系统，帮助实现极致的生鲜购物和物流配送体验的目标。',
  ],
  carouselImgs: [
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Rabbit/rabbit-1.png' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Rabbit/rabbit-2.png' alt="" />,
    <img src='https://website-1256104336.cos.ap-beijing.myqcloud.com/images/Rabbit/rabbit-3.png' alt="" />,
  ],
  showImgs: [
    <img alt="Rabbit-left" src={RabbitLeftImg}/>,
    <img alt="Rabbit-right" src={RabbitRightImg}/>,
  ]
}

export const caseDatas = [
  vineData, 
  cicadaLData, 
  cicadaPData, 
  snakeData, 
  wapipayData,  
  moegoData,  
  carezData, 
  // qihuibaoData, 
  // glassesData, 
  // kemsData, 
  // shopshopsData, 
  // prismPopData, 
  // tripalinkData, 
  // trashausData, 
  // rabbitData
]

export const homepageDatas = [rabbitData, cicadaLData, carezData, qihuibaoData]