import React, { Component, Fragment } from "react";
import { Form, Input, Button } from "antd";
import Header from "@/layouts/header";
class Login extends Component {
  render() {
    return (
      <Fragment>
        <Header/>
        <h2>Antd 测试</h2>
        <p>This is login page.</p>
        <Form name="basic" initialValues={{ remember: true }}>
          <Form.Item label="用户名：" name="userName">
            <Input />
          </Form.Item>
          <Form.Item label="密码：" name="pwd">
            <Input.Password />
          </Form.Item>
          <Form.Item>
            <Button
              className="login_btn"
              type="primary"
              htmlType="submit"
            >
              登录
            </Button>
          </Form.Item>
        </Form>
      </Fragment>
    );
  }
}

export default Login;
